<template>
  <div
    class="flex items-center w-full flex-wrap h-full"
  >
    <BaseText
      type="label"
      size="md"
      class="text-text-muted w-max"
    >
      Plan: {{ plan?.name }}
    </BaseText>
    <BaseBadge
      v-if="showDeal"
      square
      green
      class=" w-max ml-auto"
    >
      <BaseText
        type="label"
        size="xs"
        weight="normal"
      >
        99% off First Month!
      </BaseText>
    </BaseBadge>
    <BaseText
      v-if="showDeal"
      type="label"
      size="md"
      class="text-text-disabled w-max ml-2 line-through"
      weight="normal"
    >
      $99/mo
    </BaseText>
    <BaseText
      v-if="discountPrice"
      type="label"
      size="md"
      class="text-text-disabled w-max ml-2"
      weight="normal"
    >
      <span :class="loadingPromoCode ? 'animate-pulse text-transparent bg-background-disabled' : 'line-through'">
        {{ planPrice }}
      </span>
    </BaseText>
    <BaseText
      v-if="discountPrice"
      type="label"
      size="md"
      class="text-text-loud w-max"
      :class="showDeal ? 'ml-2 text-text-subdued' : 'ml-auto'"
      weight="normal"
    >
      <span :class="loadingPromoCode ? 'animate-pulse text-transparent bg-background-disabled' : ''">
        {{ formattedDiscountPrice }}
      </span>
    </BaseText>
    <BaseText
      v-else
      type="label"
      size="md"
      class="text-text-muted w-max"
      :class="showDeal ? 'ml-2 text-text-subdued' : 'ml-auto'"
      weight="normal"
    >
      <span :class="loadingPromoCode ? 'animate-pulse text-transparent bg-background-disabled' : ''">
        {{ showDeal ? '$0.99/mo' : total }}
      </span>
    </BaseText>
    <div
      v-for="(product, index) in products"
      :key="index"
      class="flex gap-2 mt-1 w-full"
    >
      <div class="flex gap-3">
        <BaseText
          type="label"
          size="md"
          class="text-text-muted w-max"
        >
          Add-Ons
        </BaseText>
        <BaseText
          type="body"
          size="md"
          class="text-text-normal w-max"
        >
          ({{ product.name + ' x' + product.amount }})
        </BaseText>
      </div>
      <BaseText
        v-if="discountPriceSpyder || perUnitPrice"
        type="label"
        size="md"
        class="text-text-disabled w-max"
        weight="normal"
      >
        <span :class="loadingPromoCode ? 'animate-pulse text-transparent bg-background-disabled' : 'line-through'">
          {{ formatProductPrice(product) }}
        </span>
      </BaseText>
      <BaseText
        type="label"
        size="md"
        class="text-text-muted w-max ml-auto"
        weight="normal"
      >
        <span :class="loadingPromoCode ? 'animate-pulse text-transparent bg-background-disabled' : ''">
          {{ formatProductPrice(product, true) }}
        </span>
      </BaseText>
    </div>
    <div
      class="w-full flex gap-2 border-t border-border-normal py-2.5 mt-2"
    >
      <BaseText
        type="label"
        size="lg"
        class="text-text-muted w-max my-auto"
        weight="normal"
      >
        Total
      </BaseText>
      <BaseText
        v-if="showDeal"
        line-through
        type="label"
        size="lg"
        class="text-text-disabled w-max ml-auto pt-0.5"
        weight="normal"
      >
        <span :class="loadingPromoCode ? 'animate-pulse text-transparent' : ''">
          $99/mo
        </span>
      </BaseText>
      <BaseText
        v-if="showDeal"
        type="label"
        size="lg"
        class="text-text-loud w-max"
        weight="normal"
      >
        <span :class="loadingPromoCode ? 'animate-pulse text-transparent bg-background-disabled' : ''">
          $0.99
        </span>
      </BaseText>
      <BaseText
        v-else
        type="label"
        size="lg"
        class="text-text-loud w-max ml-auto"
        weight="normal"
      >
        <span :class="loadingPromoCode ? 'animate-pulse text-transparent bg-background-disabled' : ''">
          {{ perUnitPrice || discountPriceSpyder ? discountTotal : fullTotal }}
        </span>
      </BaseText>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutTotal',
  props: {
    plan: {
      type: Object,
      default: () => {}
    },
    products: {
      type: Array,
      default: () => []
    },
    discountPercentage: {
      type: Number,
      default: 0
    },
    loadingPromoCode: {
      type: Boolean,
      default: false
    },
    paymentSchedule: {
      type: String,
      default: 'Monthly'
    },
    timerExpired: {
      type: Boolean,
      default: false
    },
    discountMessage: {
      type: String,
      default: ''
    },
    discountPrice: {
      type: Number,
      default: 0
    },
    perUnitPrice: {
      type: Number,
      default: 0
    },
    originalPerUnitPrice: {
      type: Number,
      default: 0
    },
    // Spyder Discounts
    discountMessageSpyder: {
      type: String,
      default: ''
    },
    discountPriceSpyder: {
      type: Number,
      default: 0
    },
    originalPriceSpyder: {
      type: Number,
      default: 0
    },
    currentCountry: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      firstMonthDiscount: 0
    }
  },
  computed: {
    showDeal () {
      return !this.timerExpired && this.paymentSchedule === 'Monthly' && this.plan?.name === 'Full Workflow'
    },
    planPrice () {
      const monthlyPrice = this.plan?.stripe_metadata_monthlyPrice
      const annualPrice = Number(this.plan?.stripe_metadata_monthlyPrice) * 12 - Number(this.plan?.stripe_metadata_annualSavings)

      const period = this.paymentSchedule === 'Monthly' ? 'mo' : 'yr'

      return `$${this.paymentSchedule === 'Monthly' ? monthlyPrice : annualPrice}/${period}`
    },
    formattedDiscountPrice () {
      const period = this.paymentSchedule === 'Monthly' ? 'mo' : 'yr'
      return `$${(this.discountPrice / 100).toFixed(2)}/${period}`
    },
    total () {
      const total = this.paymentSchedule === 'Monthly' ? this.plan.price?.tiers[0]?.flat_amount_decimal : this.plan.annualPrice.tiers[0].flat_amount_decimal
      return this.paymentSchedule === 'Monthly' ? `$${total.substring(0, (total.length - 2)) + total.substring((total.length - 2), total.length - 2)}/mo` : `$${total.substring(0, (total.length - 2)) + total.substring((total.length - 2), total.length - 2)}/yr`
    },
    fullTotal () {
      let productTotal = 0
      if (this.paymentSchedule === 'Monthly') {
        productTotal = this.products.reduce((acc, product) => {
          return acc + product.price
        }, 0)
      } else {
        productTotal = this.products.reduce((acc, product) => {
          return acc + product.price
        }, 0)
      }

      const total = (this.paymentSchedule === 'Monthly' ? this.plan.price?.tiers[0]?.flat_amount_decimal : this.plan.annualPrice.tiers[0].flat_amount_decimal)
      const checkoutTotal = productTotal + (parseInt(total) / 100)
      const paymentSchedule = this.paymentSchedule === 'Monthly' ? 'mo' : 'yr'

      if (this.currentCountry === 'CA') {
        return `$${((checkoutTotal) * 1.13).toFixed(2)}/${paymentSchedule} (HST Included)`
      }

      return `$${(checkoutTotal).toFixed(2)}/${paymentSchedule}`
    },
    discountTotal () {
      const total = this.paymentSchedule === 'Monthly' ? this.plan.price?.tiers[0]?.flat_amount_decimal : this.plan.annualPrice.tiers[0].flat_amount_decimal
      const discount = this.discountPrice
      const checkoutTotal = parseInt(total) - discount
      const paymentSchedule = this.paymentSchedule === 'Monthly' ? 'mo' : 'yr'

      // Add product total
      const productTotal = this.products.reduce((acc, product) => {
        if (product.name === 'Spyder Brands' && this.discountPriceSpyder) {
          return acc + (this.discountPriceSpyder)
        } else if (product.name === 'Team Members' && this.perUnitPrice) {
          return acc + (this.perUnitPrice * product.amount)
        }
      }, 0)

      return `$${((checkoutTotal + productTotal) / 100).toFixed(2)}/${paymentSchedule}`
    }
  },
  methods: {
    formatProductPrice (product, discounted = false) {
      const period = this.paymentSchedule === 'Monthly' ? 'mo' : 'yr'

      if (product.name === 'Spyder Brands' && this.discountPriceSpyder) {
        const priceValue = discounted ? this.discountPriceSpyder : this.originalPriceSpyder
        return `$${(priceValue / 100).toFixed(2)}/${period}`
      } else if (product.name === 'Team Members' && this.perUnitPrice) {
        const priceValue = discounted ? this.perUnitPrice : this.originalPerUnitPrice
        const billableUsers = product.amount - this.plan.price.tiers[0].up_to

        return `$${(priceValue * (billableUsers > 0 ? billableUsers : 0) / 100).toFixed(2)}/${period}`
      }

      return `$${product.price}/${period}`
    }
  }
}
</script>
